import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '45-60 minutes',
  session: '1 session',
  results: 'Results 9 - 12 months',
}

const questions: Array<Question> = [
  {
    question: 'How does this treatment work?',
    answer:
      'Fillers in this area are beneficial for many effects. ' +
      'We can perform a more defined, high cheekbone, making a face look ' +
      'slimmer and more modelled.' +
      ' As we age, this area can lose volume, creating  indentation lines (folds),' +
      ' by adding volume in the cheek, we can pull this area up, making the line' +
      ' notably less visible and adding youthful volume. ',
  },
  {
    question: 'Who is this treatment good for?',
    answer:
      'Cheek fillers are suitable for most ages as they have a wide range of uses.' +
      'Great for anyone who prefers a more contoured look. For those who would like to lift the mid areas of the face',
  },
  {
    question: 'Does it hurt?',
    answer:
      'It is not very sensitive at all so you should be comfortable during the treatment, the fillers we use also ' +
      'contain anaesthetic which will numb the area during the procedure. ',
  },
]

const CheekFillers: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Cheek Fillers - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax titleName="Cheek Fillers" customClass="page_title" />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="excerpt">
                At Ada Aesthetics we offer premium dermal fillers to add
                definition, contour and proportion to your whole face.
              </p>
              <p>
                <b>Chin enhancement</b> <br />A weak chin can distort your
                profile. Dermal fillers add definition to the chin area without
                the risk and downtime of surgery. Fillers are an excellent, safe
                alternative to permanent chin implants which avoids
                complications such as infection or movement.
              </p>
              <p>
                <b>Cheek Filler</b> <br />
                High cheekbones age well. Cheek fillers are excellent at
                defining the cheekbone or lifting the mid face as part of facial
                rejuvenation. This procedure also softens nose to mouth lines
                and gives a more rested, glowing look.
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default CheekFillers

export const pageQuery = graphql`
  query CheekFillers {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
